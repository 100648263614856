import React from 'react';
import {useParams} from 'react-router-dom';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import ProjectPropOne from '../component/project/itemProp/ProjectPropOne';
import { slugify } from '../utils';
import ServiceData from "../data/service/ServiceMain.json";
import ProjectData from "../data/project/ProjectData.json";
import AboutTwo from '../component/about/AboutTwo';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';
import Tilty from 'react-tilty';
import HeaderThree from '../common/header/HeaderThree';

const allServiceData = ServiceData;
const getProjectData = ProjectData;

const faqs =[
    {
        "question_number": 1,
        "question": "What is Edfrica?",
        "answer": "Edfrica is an AI-powered learning platform designed to help students prepare for various exams by providing personalized study plans, real-time feedback, and a comprehensive database of past exam questions and simulations."
      },
      {
        "question_number": 2,
        "question": "How does the AI feature work?",
        "answer": "Our AI analyzes your performance and identifies your strengths and weaknesses to create customized study plans and practice tests that adapt to your learning pace, optimizing your study time and improving your knowledge retention."
      },
      {
        "question_number": 3,
        "question": "Is Edfrica suitable for all types of exams?",
        "answer": "Edfrica is designed to support a wide range of exams, particularly licensure and certification exams across various fields such as finance, law, engineering, and healthcare."
      },
      {
        "question_number": 4,
        "question": "Can I use Edfrica for free?",
        "answer": "Edfrica offers a Basic package that provides access to a limited set of features for free. For full access to all features, including advanced AI insights and unlimited practice tests, you can upgrade to our Standard package."
      },
      {
        "question_number": 5,
        "question": "How can I download Edfrica?",
        "answer": "Edfrica is available for download on both the Google Play Store and Apple App Store. Simply search for 'Edfrica' and click 'Install' to start your download."
      },
      {
        "question_number": 6,
        "question": "What educational content is available in Edfrica?",
        "answer": "Edfrica provides a variety of educational content, including past exam questions, detailed explanations, customizable quizzes, and simulated exams, all curated to meet the specific requirements of different exam types."
      },
      {
        "question_number": 7,
        "question": "How do I reset my password?",
        "answer": "To reset your password, go to the login page, click on 'Forgot Password?', and follow the instructions to receive a password reset link to your registered email address."
      },
      {
        "question_number": 8,
        "question": "How can institutions use Edfrica?",
        "answer": "Institutions can benefit from our Institutional package, which offers customized solutions, including bulk user registrations, specialized content, and detailed progress tracking for groups of students or employees."
      },
      {
        "question_number": 9,
        "question": "What support options are available if I need help?",
        "answer": "You can reach our support team via email at support@edfrica.com or through our in-app support chat. Our team is available Monday through Friday from 9 AM to 5 PM."
      },
      {
        "question_number": 10,
        "question": "Can I access Edfrica offline?",
        "answer": "Currently, Edfrica requires an internet connection to access the full range of features, including AI analytics and real-time feedback. However, you can use some features offline, such as accessing downloaded materials and notes."
      }
]

const Faqs = () => {

    const params = useParams();
    const serviceSlug = params.slug;

    const getServiceData = allServiceData
    const detailsService = getServiceData[0];

    console.log(allServiceData)

    return (
        <>
        <SEO title="Frequently Asked Questions" />
        <ColorSwitcher />
        <main className="main-wrapper">
            <HeaderThree />
            <BcrumbBannerOne 
            title="Frequently Asked Questions"
            paragraph ="Here you'll find answers to some of the most common questions about Edfrica. "
            styleClass=""
  
            />

           
            <div className="section section-padding bg-color-light pb--70">
                <SectionTitle 
                    subtitle="Questions"
                    textAlignment=""
                    textColor="mb--90"
                />
                <div className="container">
                    {faqs.map((data, index) => (
                        <div key={index} className={`process-work ${(index % 2  === 1) ? "content-reverse" : ""}`}>
                        <div className="content">
                            <span className="subtitle">{data.question_number}</span>
                            <h3 className="title">{data.question}</h3>
                            <p>{data.answer}</p>
                        </div>
                    </div>
                    ))}
                </div>
                <ul className="shape-group-17 list-unstyled">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-24.png"} alt="Bubble" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-23.png"} alt="Bubble" /></li>
                    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="Line" /></li>
                    <li className="shape shape-4"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="Line" /></li>
                    <li className="shape shape-5"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="Line" /></li>
                    <li className="shape shape-6"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="Line" /></li>
                </ul>
            </div>

            
        <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default Faqs;